<template>
  <div class="code-editor">
    <edDropdown :selected="selectedLanguage" :listItems="languages" :isSelected="setLanguage" styles="max-width: 8rem" customClass="ed-dropdown__sm my-2"/>
    <prism-editor v-if="!isCompiling" class="my-editor" v-model="code" :highlight="highlighter" :tabSize="4" line-numbers></prism-editor>
    <button class="ed-btn ed-btn__blim my-2" @click="compileCode()">Compile</button>
    <div class="code-editor__output">
        {{ output }}
    </div>
  </div>
</template>

<script>
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
import edDropdown from '@/common/components/ed-forms/ed-dropdown.vue';

export default {
  components: {
    PrismEditor,
    edDropdown,
  },
  data: () => ({
    code: '/* Write your Code here */', languages: ['node'], selectedLanguage: 'node', output: '',
  }),
  computed: {
    isCompiling() {
      return this.$store.state.codeCompiler.isCompiling;
    },

  },
  methods: {
    highlighter(code) {
    //   console.log('xvf', languages);
      return highlight(code, languages.clike); // languages.<insert language> to return html with markup
    },
    compileCode() {
      this.$store.dispatch('codeCompiler/getCompiledOutput', {
        lang: this.selectedLanguage,
        code: this.code,
      }).then((response) => {
        console.log('xvf', response);
        this.output = response.output;
        this.$store.dispatch('codeCompiler/stopCompiling');
      }).catch(() => {
        this.output = 'There is some error here';
      });
    },
    setLanguage(value) {
      this.selectedLanguage = value;
    },
  },
};
</script>

<style lang="scss">
.code-editor {
    /* required class */
  .my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #333333;
    color: #fdc400;

    /* you must provide font-family font-size line-height. Example: */
    font-family: monospace;
    font-size: 14px;
    line-height: 2;
    padding: 5px;
    height: 420px;
  }

  /* optional class for removing the outline */

}
.prism-editor-wrapper {
    height: 480px;
}

.prism-editor__container {
    outline: none !important;
    .prism-editor__textarea:focus {
      outline: none;
    }
}
</style>
